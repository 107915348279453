export const areaUnit = [
  'Square Feets',
  'Square Yards',
  'Square Meter',
  'Square Mile',
  'Acre',
  'Guntas',
  'Bigha',
  'Hector',
  'Marla',
  'Kanal',
  'Cent',
  'Are',
];

export const countryCode = [
  { name: 'India +91', value: +91 },
  { name: 'Australia + 61', value: +61 },
  { name: 'Canada +1', value: +1 },
  { name: 'New Zealand +64', value: +64 },
  { name: 'United Arab Emirates +971', value: +971 },
  { name: 'United Kingdom +44', value: +44 },
  { name: 'United States +1', value: +1 },
];
export const floor = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
];

export const plotLayoutType = ['GHMC', 'HMDA', 'MUNICIPALITY', 'GRAM PANCHAYAT'];
// { name: "Villa Community Projects", value: "villa_community_project" }, { name: "Apartment Community Project", value: "apartment_community_project" }
export const sale = [{ name: "Land", value: "land" }, { name: "Plot", value: "plot" }, { name: "Flat", value: "flat" }, { name: "Residential House", value: "residential_house" }, { name: "Villa", value: "villa" }, { name: "Villa & Flat Community Projects", value: "villa_apartment_community_project" }, { name: "Commercial Land/Plot", value: "commercial_plot" }, { name: "Commercial Office Space", value: "commercial_office_space" }, { name: "Commercial Shutter", value: "commercial_shutter" }]

export const rent = [{ name: "Land", value: "land" }, { name: "Plot", value: "plot" }, { name: "Flat", value: "flat" }, { name: "Residential House", value: "residential_house" }, { name: "Villa", value: "villa" }, { name: "Commercial Land/Plot", value: "commercial_plot" }, { name: "Commercial Office Space", value: "commercial_office_space" }, { name: "Commercial Shutter", value: "commercial_shutter" }]

export const pg_hostel_flat = [{ name: 'Paying Guest(PG)', value: "paying_guest" }, { name: 'Hostel', value: 'hostel' }, { name: 'Flat Sharing', value: 'flat_sharing' }]

export const country_codes = [
  { code: '91', prefix: 10 }, { code: '61', prefix: 9 }, { code: '880', prefix: 10 }, { code: '55', prefix: 11 }, { code: '1', prefix: 10 }, { code: '86', prefix: 11 }, { code: '45', prefix: 8 }, { code: '33', prefix: 9 }, { code: '49', prefix: 10 }, { code: '353', prefix: 9 }, { code: '972', prefix: 9 }, { code: '39', prefix: 10 }, { code: '81', prefix: 10 }, { code: '60', prefix: 7 }, { code: '977', prefix: 10 }, { code: '64', prefix: 10 }, { code: '47', prefix: 8 }, { code: '48', prefix: 9 }, { code: '351', prefix: 9 }, { code: '7', prefix: 10 }, { code: '65', prefix: 8 }, { code: '94', prefix: 7 }, { code: '41', prefix: 9 }, { code: '66', prefix: 9 }, { code: '971', prefix: 9 }, { code: '44', prefix: 10 }
]
