import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  public base_url: any;
  constructor(public http: HttpClient, private authService: AuthService) {
    this.base_url = environment.base_url;

  }
  addFavouriteProperty(id) {
    return this.http.post<any>(this.base_url + "api/v1/user/addFavProperties", id);
  }

  async updateProfile(data) {
    let headers = new HttpHeaders({
      'Accept': 'application/json, text/plain, application/xml,  */*',
      "Content-Type": "application/json",
      'Access-Control-Allow-Headers': 'Content-Type',
    });
    let options = {
      headers: headers
    }
    return this.http
      .post<any>(this.base_url + "api/v1/user/profile/update", data, options);
  }

  changePassword(data) {
    console.log(data);
    return this.http.post<any>(this.base_url + "api/user/change_password", data);
  }

  getProperties(data) {
    return this.http.get<any>(this.base_url + `api/v1/user/properties?limit=${data.limit}&offset=${data.offset}`);
  }

  getFavProperties() {
    return this.http.get<any>(this.base_url + "api/v1/user/getFavProperties");
  }

  deleteProperty(id) {
    return this.http.delete<any>(this.base_url + "api/v1/user/delete/" + id);
  }

  deleteFavProperty(id) {
    return this.http.delete<any>(this.base_url + "api/v1/user/removeFavProperties/" + id);

  }

  async getProfileDetails() {
    return this.http.get<any>(this.base_url + "api/v1/user/profile");

  }

  sendMobileOtp(mobile_number) {
    return this.http.get<any>(`${this.base_url}api/v1/phone/send_otp/${mobile_number}`)
  }

  verifyOTP(otp, session_id) {
    return this.http.get<any>(`${this.base_url}api/v1/phone/verify_otp/${session_id}/${otp}`)
  }
}
