import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../_services/api.service';
import { Router } from '@angular/router';
import { ProfileService } from '../../_services/profile.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { InteractionService } from './../../_services/interaction.service';
import firebase from 'firebase/app';
import 'firebase/storage';
import { sale, rent, pg_hostel_flat } from 'src/app/common/common';
import { CommomService } from '../../common/commom.service';
import { trigger, transition, animate, style } from '@angular/animations'
import { last } from '@angular/router/src/utils/collection';
import { ToastrManager } from 'ng6-toastr-notifications';
declare var $: any;
// var httpsReference = storage.refFromURL('gs://authentication-62937.appspot.com/land.jpg');
// var storageRef = storage.ref();

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate(100, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class HomeComponent implements OnInit {
  public searchForm: FormGroup;
  public submited = false;
  public districts: any;
  public locations: any;
  public suggestions: any;
  sale = sale;
  rent = rent;
  propertyTypes = sale;
  propety_type = []
  propertyFor = 'sale'
  selectedItems
  latlng: any;
  latestProperty: any;
  address: Object;
  i = 0;
  img = 'assets/default.jpg';
  images = ['assets/img/intro-carousel/1.jpg', 'assets/img/Properties 9_Banner_02.jpg'];
  dropdownSettings = {}
  dropdownList = [];
  displayFull = false;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private router: Router, private sanitizer: DomSanitizer, private IntService: InteractionService, private commonService: CommomService,public toastr: ToastrManager,) {
    this.IntService.currentRoute("home")
  }

  ngOnInit() {


    this.getLatestProperty();
    this.propertyTypes.forEach(ele => {
      let data = { item_id: "", item_text: "" }
      data.item_id = ele.value;
      data.item_text = ele.name
      this.dropdownList.push(data)

    })



    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: false,
      enableCheckAll: false
    };
    this.searchForm = this.fb.group({
      // property_for: [''],
      lattitude: ['', Validators.required],
      longitude: [''],
      // address:[''],
      // property_type: ['', Validators.required]
    });
    window.scroll(0, 0);

    this.loadScript();
    // });





  }

  directAbout() {
    var parts = window.location.href.split("/");
      var result = parts[parts.length - 1];
      if(result == "#about") {
      //setTimeout(function(){document.location.href = result},500);
      document.getElementById('about').scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' });
    }
    this.directAbout = function(){};
    var myobj = document.getElementById("toberemoved");
    myobj.remove();
  }

  public loadScript() {
    console.log("preparing to load...");
    let node = document.createElement("script");
    node.type = "text/javascript";
    node.async = true;
    node.charset = "utf-8";
  }

  /* Return Form Controls  */
  get f() { return this.searchForm.controls }
  /* Get Suggestion */
  getSuggestion(input) {
    if (input === null || input === undefined || input == '') {
      this.suggestions = undefined;
    }
    this.suggestions = this.locations.filter(s => s.name.toLocaleLowerCase().startsWith(input.toLocaleLowerCase()));
  }
  setSuggestion(suggestionName) {
    this.searchForm.patchValue({ location: suggestionName });
    this.suggestions = undefined;
  }

  displayFullFunc() {
    this.displayFull = !this.displayFull;

    if (this.displayFull) {
      document.getElementById('displayBtn').innerHTML = "READ LESS";
    }
    else {
      document.getElementById('displayBtn').innerHTML = "READ MORE";
      document.getElementById('aboutUs').scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' });
    }
  }

  onChangeCategory(value) {
    switch (value) {
      case 'sale':
        this.propertyTypes = sale;
        this.dropdownList = []
        sale.forEach(ele => {
          let data = { item_id: "", item_text: "" }
          data.item_id = ele.value;
          data.item_text = ele.name
          this.dropdownList.push(data)
        })
        this.propertyFor = 'sale'
        this.selectedItems = []
        break;
      case 'rent':
        this.propertyTypes = rent;
        this.dropdownList = []
        this.propertyTypes.forEach(ele => {
          let data = { item_id: "", item_text: "" }
          data.item_id = ele.value;
          data.item_text = ele.name
          this.dropdownList.push(data)
        })
        this.propertyFor = 'rent'
        this.selectedItems = []
        break;
      case 'lease':
        this.propertyTypes = rent;
        this.dropdownList = []
        this.propertyTypes.forEach(ele => {
          let data = { item_id: "", item_text: "" }
          data.item_id = ele.value;
          data.item_text = ele.name
          this.dropdownList.push(data)
        })

        this.propertyFor = 'lease'
        this.selectedItems = []
        break;
      default:
        this.propertyTypes = pg_hostel_flat;
        this.dropdownList = []
        this.propertyTypes.forEach(ele => {
          let data = { item_id: "", item_text: "" }
          data.item_id = ele.value;
          data.item_text = ele.name
          this.dropdownList.push(data)
        })
        this.propertyFor = 'rent'
        this.selectedItems = []
    }
  }

  onItemDeSelect(event) {
    if (this.propety_type.indexOf(event.item_id) !== -1) {
      this.propety_type.splice(event.item_id, 1)
    }
  }
  /* Search Property */
  Search() {
    this.selectedItems.forEach(element => {
      if (this.propety_type.indexOf(element.item_id) === -1) {
        this.propety_type.push(element.item_id)
      }

    });
    this.submited = true;
    if (this.searchForm.valid && this.propety_type.length > 0) {
      let data = this.searchForm.value;
      data['property_for'] = this.propertyFor
      data['property_type'] = this.propety_type.toString()
      localStorage.removeItem('p')
      localStorage.removeItem('property_for')
      this.router.navigate(['/search-details/', JSON.stringify(data)]);
    }
  }

  goToDetailPage(latest) {
    const detailsPath = [
                            latest.id ,
                            latest.City || '',
                            latest.Location || '',
                            "FOR",                                // Constant value
                            latest.Property_For || '',
                            latest.Property_Type || '',
                            latest.Area || '',
                            latest.area_unit || '',
                            latest.Property_Name || '',           // Fallback for missing Property_Name
                            latest.Total_Price || ''
                          ].map(value => value.toString().replace(/[^a-zA-Z0-9]/g, '-')) // Replace non-alphanumeric characters with `-`
                                  .join('-')                                                // Join values with `-`
                                  .replace(/-+/g, '-');

    this.router.navigate([]).then(
      result => { window.open(`/property-details/${latest.id}/${detailsPath}`, '_blank'); }
    );
  }


  goto1() {
    console.log(0);

  }
  showPropertyModal() {
    $("#postPropertyModal").modal("show");
  }

  getLatestProperty() {
    this.apiService.getLatestProperty().subscribe(res => {
      console.log("property details", res);
      res.forEach(element => {
        if (element.Total_Price) {
          element.Total_Price = this.commonService.addCommaToPrice(element.Total_Price);
        } else {
          if (element.Price_Per_Unit)
            element.Price_Per_Unit = this.commonService.addCommaToPrice(element.Price_Per_Unit);
        }
      });
      this.latestProperty = res;

      // get image urls
      var storageRef = firebase.storage().ref();

      this.latestProperty.forEach(element => {
        if (element.property_images.length > 0 && element.property_images[0].name.split('.')[1] !== 'pdf') {
          // storageRef.child(element.property_images[0].name).getDownloadURL().then((url) => {
          //   element.url = url
          // }).catch(error => {
          //   console.log(error)
          // })
          storageRef.child(element.property_images[0].name).getDownloadURL().then(onResolve, onReject);


        }
        function onResolve(foundURL) {
          element.url = foundURL
          //stuff
        }

        function onReject(error) {
          var img = false
          for(var i = 0; i<element.property_images.length;i++) {
          storageRef.child(element.property_images[0].name).getDownloadURL().then((url) => {
            element.url = url
          }).catch(error => {
            console.log(error)
            element.url = '../../../assets/img/no-image.png'
          })
          if(img) {
            break
          }
        }
        }
        // else{
        //   storageRef.child(element.property_images[1].name).getDownloadURL().then((url) => {
        //     element.url = url
        //   }).catch(error => {
        //     console.log(error)
        //   })
        // }

      });

    })


  }

  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }




  getAddress(place) {
    this.address = place['formatted_address'];
    console.log(this.address);
    console.log(place);
    console.log(place['geometry']['location'].toJSON()); this.latlng = place['geometry']['location'].toJSON();
    console.log(this.latlng);
    this.searchForm.patchValue({ lattitude: this.latlng.lat });
    this.searchForm.patchValue({ longitude: this.latlng.lng });
    //this.searchForm.patchValue({address:this.address});

    for (let i = 0; i < place.address_components.length; i++) {
      if (place.address_components[i].types[0] == "street_number") {
        console.log('yesssss');
      }
    }
  }

  latestPropertyClickCount(key) {

    key == 'add' ? this.i = this.i + 1 : this.i = this.i - 1;

  }

  redirectToSearchProperty() {
    this.router.navigate(['/all-properties']);
  }


  closeModel() {
    $("#postPropertyModal").modal("hide");
  }

  clear() {
    window.location.reload()
  }

  sendContactUsEmail(contactForm: any) {
      const formDataJson = {
      name: contactForm.value.name,
      email: contactForm.value.email,
      mobileNumber: contactForm.value.mobileNumber,
      subject: contactForm.value.subject,
      message: contactForm.value.message,
    };

    console.log('Form Data as JSON:', formDataJson);


  // Send the FormData to the backend
  this.apiService.sendContactUsMail( formDataJson).subscribe(
    (response) => {
       this.toastr.successToastr("Sucessfully sent message", null, { showCloseButton: true });
        contactForm.reset();
    },
    (error) => {
      this.toastr.warningToastr('Internal Server Error', 'Alert!', { showCloseButton: true });
    }
  );
}

}
