import { Injectable } from '@angular/core';
import { format } from 'url';

@Injectable({
  providedIn: 'root'
})
export class CommomService {

  constructor() { }

  addCommaToPrice(value: string) {
    // return value
    //   .replace(/\D/g, '')
    //   .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    let number
    if (value.includes(',')) {
      number = value.replace(/,/g, "");
    } else {
      number = value
    }
    var result = number.split('.');

    var lastThree = result[0].substring(result[0].length - 3);
    var otherNumbers = result[0].substring(0, result[0].length - 3);
    if (otherNumbers != '')
      lastThree = ',' + lastThree;
    var output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;

    if (result.length > 1) {
      output += "." + result[1];
    }
    return output

  }

  isInputNumber(ch, event) {
    if (!(/[0-9]/.test(ch))) {
      return event.preventDefault();
    }
  }

  validateNosAndDecimal(ch) {
    var validNumber = new RegExp(/^\d*\.?\d*$/);
    if (!(validNumber.test(ch))) {
      return true;
    } else {
      return false;
    }
  }


  convertDate(day, month, year) {
    let formatMonth;
    switch (month) {
      case 1:
        formatMonth = '01'
        break;
      case 2:
        formatMonth = '02'
        break;
      case 3:
        formatMonth = '03'
        break;
      case 4:
        formatMonth = '04'
        break;
      case 5:
        formatMonth = '05'
        break;
      case 6:
        formatMonth = '06'
        break;
      case 7:
        formatMonth = '07'
        break;
      case 8:
        formatMonth = '08'
        break;
      case 9:
        formatMonth = '09'
        break;
      default:
        formatMonth = month.toString()

    }
    if (day.toString().length < 2) {
      day = `0${day}`
    }
    return `${year}-${formatMonth}-${day}`;

  }
}
