import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: './pages/home/home.module#HomeModule'
  },
  {
    path: "sell-edit/:id",
    loadChildren: './pages/sell-edit/sell-edit.module#SellEditModule'
  },
  {
    path: "rent-edit/:id",
    loadChildren: './pages/rent-edit/rent-edit.module#RentEditModule'
  },
  {
    path: "home",
    pathMatch: 'full',
    redirectTo: ''
  },
  {
    path: "terms",
    loadChildren: './pages/terms/terms.module#TermsModule'
  },
  {
    path: "privacy",
    loadChildren: './pages/privacy/privacy.module#PrivacyModule'
  },
  {
    path: "profile",
    loadChildren: './pages/profile/profile.module#ProfileModule'
  },
  {
    path: "buy",
    loadChildren: './pages/search-details/search-details.module#SearchDetailsModule'
  },
  {
    path: "sell-property",
    loadChildren: './pages/sell/sell.module#SellModule'
  },
  {
    path: "pg-hostel-page",
    loadChildren: './pages/pg-hostel-page/pg-hostel-page.module#PgHostelPageModule'
  },
  {
    path: "pg-hostel-page/:id",
    loadChildren: './pages/pg-hostel-page/pg-hostel-page.module#PgHostelPageModule'
  },
  {
    path: "rent/:property_for",
    loadChildren: './pages/rent/rent.module#RentModule',
    runGuardsAndResolvers: 'always'
  },
  {
    path: "search-details/:SearchDetails",
    loadChildren: './pages/search-details/search-details.module#SearchDetailsModule'
  },
  {
    path: "property-details/:id/:detailsPath",
    loadChildren: './pages/property-details/property-details.module#PropertyDetailsModule'

  },
  {
    path: 'search-details',
    loadChildren: './pages/search-details/search-details.module#SearchDetailsModule'
  },
  {
    path: "favorite",
    loadChildren: './pages/favorites/favorites.module#FavoritesModule'
  },
  {
    path: "properties",
    loadChildren: './pages/property-listing/property-listing.module#PropertyListingModule'
  },
  {
    path: "all-properties",
    loadChildren: './pages/all-properties/all-properties.module#AllPropertiesModule'
  },
  {
    path: '**',
    redirectTo: '/home',
    pathMatch: 'full'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
