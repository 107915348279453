import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from "@angular/common/http";
import { from, Observable } from "rxjs";
import { AuthService } from '../_services/auth.service';
import { catchError, switchMap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class InterceptorService {

  constructor(private authService: AuthService) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let currentUser = localStorage.getItem("access_token");
    if (currentUser) {
      currentUser = 'Bearer ' + currentUser;
      request = request.clone({
        setHeaders: {
          Authorization: ` ${currentUser}`
        }
      });
    }

    // return next.handle(request);

    return next.handle(request).pipe(
      catchError(err => {
        return this.refreshToken(request, next);
      })
    );
  }


  refreshToken(request: any, next: any) {
    // By making use of the from operator of RxJS convert the promise to an observable
    return from(this.authService.GetToken()).pipe(
      switchMap(t => this.updateTokenAndRetry(request, next, t))
    )
  }

  updateTokenAndRetry(request: HttpRequest<any>, next: HttpHandler, token: string): Observable<HttpEvent<any>> {
    // Update local stored user
    console.log("token", token)
    localStorage.setItem("access_token", token);
    console.log(localStorage.getItem("access_token"))
    // Add the new token to the request
    request = request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + token,
      },
    });

    return next.handle(request);
  }

}
